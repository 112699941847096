import React, { Fragment } from "react";
import Header from "./header";
import { isEmpty } from "lodash";
import CustomLoader from "../common/CustomLoader";
import AnalysisChart from "../charts/AnalysisChart";

interface IProps {
  parsedCsvFiles: any;
  csvFields: string[];
  isModalOpen: boolean;
  isAnalyzing: boolean;
}

const Visualization: React.FC<IProps> = ({
  parsedCsvFiles,
  csvFields,
  isModalOpen,
  isAnalyzing,
}) => {
  return (
    <div className=" relative graph-container" id={"graph-container"}>
      <Header text="Visualization" />
      {!isEmpty(parsedCsvFiles) &&
        !isEmpty(csvFields) &&
        isModalOpen &&
        !isAnalyzing && (
          <Fragment>
            {csvFields
              ?.filter((item) => !item.includes("Time"))
              .map((item) => (
                <AnalysisChart
                  key={item}
                  chartData={parsedCsvFiles[0].map(
                    (row: any, index: number) => ({
                      x: index,
                      y: parseFloat(row[item]),
                    })
                  )}
                  keyName={item}
                  label={item}
                />
              ))}
          </Fragment>
        )}
      {isAnalyzing && (
        <div className="absolute width-full height-full full-flex opacity-4">
          <CustomLoader isFixed={false} />
        </div>
      )}
    </div>
  );
};

export default Visualization;
