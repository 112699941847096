import React, { useState, useRef, useEffect } from "react";
import Highcharts, { Options as HighchartsOptions } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import AnnotationsModule from "highcharts/modules/annotations";

if (typeof AnnotationsModule === "function") {
  AnnotationsModule(Highcharts);
}

interface AnalysisChartProps {
  chartData: { x: number; y: number }[];
  keyName: string;
  label?: string;
}

const AnalysisChart: React.FC<AnalysisChartProps> = ({
  chartData,
  keyName,
  label,
}) => {
  const chartRef = useRef<HighchartsReact.RefObject>(null);
  const [annotations, setAnnotations] = useState<
    Highcharts.AnnotationsOptions[]
  >([]);
  const [selectedStats, setSelectedStats] = useState<{
    min: number;
    max: number;
    average: number;
    median: number;
    mode: number;
  } | null>(null);

  const [chartOptions, setChartOptions] = useState<HighchartsOptions>({
    chart: {
      type: "line",
      zooming: {
        type: "x",
      },
    },
    title: {
      text: label || "Visualization Chart",
    },
    xAxis: {
      categories: chartData.map((point) => point.x.toString()),
      title: { text: "Time" },
    },
    yAxis: {
      title: { text: "Temparature (C)" },
    },
    series: [
      {
        type: "line",
        name: label || keyName || "Series",
        data: chartData.map((point) => point.y),
      },
    ],
    tooltip: {
      shared: true,
    },
    credits: {
      enabled: false, // Disable credits
    },
    annotations: [],
  });

  const calculateStats = (data: { x: number; y: number }[]) => {
    const yValues = data.map((point) => point.y);
    const modeMap = yValues.reduce(
      (acc, val) => acc.set(val, (acc.get(val) || 0) + 1),
      new Map<number, number>()
    );
    const mode = Array.from(modeMap.entries()).reduce((a, b) =>
      a[1] > b[1] ? a : b
    )[0];
    return {
      min: Math.min(...yValues),
      max: Math.max(...yValues),
      average: yValues.reduce((sum, val) => sum + val, 0) / yValues.length,
      median: yValues.sort((a, b) => a - b)[Math.floor(yValues.length / 2)],
      mode,
    };
  };

  const handleSelection = (e: any) => {
    if (!e.xAxis || !e.xAxis[0]) return;
    const min = Math.floor(e.xAxis[0].min as number);
    const max = Math.ceil(e.xAxis[0].max as number);
    const selectedData = chartData.filter(
      (point) => point.x >= min && point.x <= max
    );
    const stats = calculateStats(selectedData);
    setSelectedStats(stats);

    setAnnotations([
      {
        labels: [
          {
            point: {
              x: (min + max) / 2,
              y: stats.max,
              xAxis: 0,
              yAxis: 0,
            },
            text: `
              <b>Min:</b> ${stats.min}<br/>
              <b>Max:</b> ${stats.max}<br/>
              <b>Avg:</b> ${stats.average.toFixed(2)}<br/>
              <b>Median:</b> ${stats.median}<br/>
              <b>Mode:</b> ${stats.mode}
            `,
          },
        ],
      },
    ]);
  };

  const handleResetZoom = () => {
    setSelectedStats(null);
    setAnnotations([]);
    chartRef.current?.chart?.zoomOut();
  };
  const handleMouseWheel = (event: WheelEvent) => {
    event.preventDefault();
    const chart = chartRef.current?.chart;
    if (!chart) return;

    const xAxis = chart.xAxis[0];
    const extremes = xAxis.getExtremes();
    const range = extremes.max - extremes.min;
    const zoomFactor = 0.1;

    let newMin, newMax;
    if (event.deltaY < 0) {
      newMin = extremes.min + range * zoomFactor;
      newMax = extremes.max - range * zoomFactor;
    } else {
      newMin = extremes.min - range * zoomFactor;
      newMax = extremes.max + range * zoomFactor;
    }

    xAxis.setExtremes(
      Math.max(extremes.dataMin, newMin),
      Math.min(extremes.dataMax, newMax)
    );
  };
  useEffect(() => {
    const chartContainer = chartRef.current?.chart.container;
    if (chartContainer) {
      chartContainer.addEventListener("wheel", handleMouseWheel);
    }

    return () => {
      if (chartContainer) {
        chartContainer.removeEventListener("wheel", handleMouseWheel);
      }
    };
  }, []);

  useEffect(() => {
    setChartOptions((prev) => ({
      ...prev,
      annotations,
    }));
  }, [annotations]);

  return (
    <div style={{ margin: "20px 0" }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          ...chartOptions,
          chart: {
            ...chartOptions.chart,
            events: {
              selection: handleSelection, // Selection logic
            },
          },
        }}
        ref={chartRef}
      />
      <button
        onClick={handleResetZoom}
        style={{
          marginTop: "10px",
          padding: "10px",
          backgroundColor: "#ff0000",
          color: "#fff",
          border: "none",
          borderRadius: "4px",
          cursor: "pointer",
        }}
      >
        Reset Analytics
      </button>
    </div>
  );
};

export default AnalysisChart;
